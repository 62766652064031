@use '../definitions' as *;

/* niakampf */
.niakampf {
    background-color: $color-gray-8;
    min-height: 100vh;
    padding-top: 3em;

    h1 {
        font-size: 1.63em;
    }

    p {
        font-size: 1em;
        line-height: 1.32;
    }

    .niakampf-border {
        align-items: center;
        background-color: $color-white;
        border: 1px solid $color-gray-9;
        border-radius: 0.75em;
        display: flex;
        flex-direction: column;
        margin: auto;
        max-width: 678px;
        padding: 3.75em 6.8em 3em;

        @media (max-width: 890px) {
            padding: 3.75em 2em 3em;
        }

        @media (max-width: 678px) {
            margin: 0 0.5em;
        }

        hr {
            border-top: 1px solid $color-gray-9;
            margin: 0 auto;
            width: 100%;
        }

        p {
            max-width: 335px;
        }
    }

    .niakampf-bottom {
        color: $color-gray-7;
        font-size: 0.75em;
        margin: 1.25em auto;
        max-width: 678px;

        * {
            color: $color-gray-7;
        }

        a {
            font-size: 1em;
            text-decoration: underline;
        }
    }

    form {
        margin: 0 auto;
        width: 100%;

        .email-box {
            display: flex;
            flex-direction: column;
            margin: auto;
            max-width: 18.75em;
            padding: 0.6em 0;
            width: 18.75em;

            @media (max-width: 40em) {
                max-width: 100%;
            }
        }
    }

    ::placeholder {
        color: $color-gray-7;
    }

    label {
        color: $color-primary;
        font-size: 0.7em;
        margin: 0;
    }

    input {
        border: 1px solid $color-gray-5;
        border-radius: 4px;
        font-size: 1em;
        line-height: 1;
        padding: 0.54em 0.75em;
        transition: all 0.157s ease-in-out;
    }

    .btn {
        border-radius: 3px;
        display: inline-block;
        font-family: $font-bold;
        font-size: 1em;
        margin-top: 1.6em;
        padding: 0.4em 1.5em;
        text-align: center;
        text-decoration: none;
        text-transform: none;
        transition: all 0.157s ease-in-out;
    }

    .btn-primary {
        background-color: $color-primary;
        border: 1px solid $color-primary;
        color: $color-white;

        &:hover,
        &:focus,
        &:active:focus {
            background-color: $color-primary-hover;
            border: 1px solid $color-primary-hover;
            box-shadow: none;
        }
    }

    .btn-link {
        background-color: $color-white;
        border: 1px solid $color-primary;
        color: $color-primary;

        &:hover,
        &:focus,
        &:active:focus {
            background-color: $color-primary-hover;
            border: 1px solid $color-primary-hover;
            box-shadow: none;
            color: $color-white;
        }
    }

    .btn-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 100%;
    }

    .mb-38px {
        margin-bottom: 38px;
    }

    .w-100-inside {
        width: 100%;

        p {
            max-width: 100%;
        }
    }

    .min-vw-10em {
        min-width: 10em;
    }

    .mojeid-address {
        font-family: $font-bold;

        span {
            display: block;
        }
    }

    input[type='checkbox'] {
        appearance: none;
        border: 0;
        bottom: -0.2em;
        box-shadow: none;
        position: relative;
    }

    .checkbox-with-link {
        align-items: center;
        display: flex;
        justify-content: space-between;

        label {
            display: none;
        }

        p {
            max-width: 440px;
        }

        input[type='checkbox'] {
            padding: 1em;
        }
    }

    .niakampf-border form p {
        max-width: 100%;
    }

    input[type='checkbox']::before,
    input[type='checkbox']::after {
        content: '';
        display: inline-block;
        position: absolute;
    }

    input[type='checkbox']::before {
        border: 1px solid $color-gray-5;
        height: 1.2em;
        left: 0;
        top: 4px;
        width: 1.2em;
    }

    input[type='checkbox']::after {
        border-bottom: 2px solid $color-gray;
        border-left: 2px solid $color-gray;
        height: 7px;
        left: 3px;
        top: 10px;
        transform: rotate(-45deg);
        width: 15px;
    }

    label + input[type='checkbox']::after {
        content: none;
    }

    label + input[type='checkbox']:checked::after {
        content: '';
    }
}

ul.messagelist {
    background-color: $color-primary;

    li.info {
        background-color: $color-primary;
        color: $color-white;
        padding: 0 0.6em;

        @media (max-width: 40em) {
            font-size: 0.85em;
        }
    }
}
