@use '../definitions' as *;
@use '../mixins';

/* global */

* {
    box-sizing: border-box;
    outline: none;
}

body {
    font-family: $font-regular;
    overflow-x: hidden;
}

.margin-0 * {
    margin: 0;
}

.padding-0 * {
    padding: 0;
}

// text color
.text-black * {
    color: $color-black;
}

.text-white * {
    color: $color-white;
}

.text-primary * {
    color: $color-primary;
}

.text-yellow * {
    color: $color-yellow;
}

.text-gray-2 * {
    color: $color-gray-2;
}

.text-gray-14 * {
    color: $color-gray-14;
}

// background color
.bg-grey {
    background-color: $color-gray-3;
}

.bg-black {
    background-color: $color-black;
}

.bg-white {
    background-color: $color-white;
}

.bg-color-primary {
    background-color: $color-primary;
}

.bg-yellow {
    background-color: $color-yellow;
}

.bg-gray-2 {
    background-color: $color-gray-2;
}

.bg-gray-3 {
    background-color: $color-gray-3;
}

.bg-gray-14 {
    background-color: $color-gray-14;
}

.bg-gray-15 {
    background-color: $color-gray-15;
}

// font-weight
.weight-700 * {
    font-family: $font-bold;
}

.weight-600 * {
    font-family: $font-semi-bold;
}

.weight-500 * {
    font-family: $font-medium;
}

.weight-400 * {
    font-family: $font-regular;
}

h1,
h2,
h3,
h4,
p,
td,
a,
li,
pre,
blockquote,
q,
input,
textarea {
    color: $color-black;
    font-family: $font-regular;
    font-size: 18px;
    line-height: 1.8;
    text-decoration: none;
}

h1,
h2,
h3 {
    font-family: $font-bold;
    line-height: 1.2;
}

h4,
h5,
h6 {
    font-family: $font-semi-bold;
}

.font-weight-bold {
    font-family: $font-bold;
}

/* links */
a {
    border-bottom: 1px solid transparent;
    cursor: pointer;
    transition: 0.4s;

    &:focus {
        outline: none;
        text-decoration: none;
    }

    &:hover {
        text-decoration: none;
    }

    .icon,
    .fa {
        transition: 0.4s;
    }
}

.list-hide-bullets {
    ul,
    ol {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
        }
    }
}

.row::before {
    content: none;
}

/* have to overwrite normalize.css because figure is used for svg images */
figure {
    margin: 0;
}

.download-links {
    .top-title,
    .hl {
        color: $color-gray !important;
    }
}

/* buttons */

.btn {
    border: 0;
    border-radius: 3px;
    display: inline-block;
    font-family: $font-bold;
    font-size: 14px;
    outline: 0;
    padding: 14px 28px;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
        color: $color-white;
    }

    &.btn-primary {
        background: $color-yellow;
        border: 1px solid $color-yellow;
        color: $btn-color;
        font-size: 12px;
        padding-left: 34px;
        padding-right: 34px;

        &:hover {
            background: $list-number;
            border: 1px solid $list-number;
            color: $color-white;
        }

        &:focus {
            background: $color-yellow;
            border: 1px solid $color-yellow;
            outline: none;
        }

        &:active {
            background: $color-yellow;
            border: 1px solid $color-yellow;
            outline: none;

            &:focus {
                background: $color-yellow;
                border: 1px solid $color-yellow;
                outline: none;
            }
        }
    }

    &.btn-login--social {
        background: $color-gray-3;
        border: 1px solid $color-gray-5;
        color: $color-gray !important;
        font-size: 12px;
        padding-left: 30px;
        padding-right: 30px;

        &:hover {
            background: $color-gray-9;
            border: 1px solid $color-gray-13;
        }

        &:focus {
            background: $color-gray-9;
            border: 1px solid $color-gray-13;
            outline: none;
        }

        &:active {
            background: $color-gray-9;
            border: 1px solid $color-gray-13;
            outline: none;

            &:focus {
                background: $color-gray-9;
                border: 1px solid $color-gray-13;
                outline: none;
            }
        }
    }

    &.btn-lg {
        font-size: 16px;
        padding: 23px 54px;

        @media (max-width: 440px) {
            max-width: 250px;
            padding-left: 30px;
            padding-right: 30px;
            width: 100%;
        }
    }
}

.btn-large-box,
.btn-large-box-background {
    @include mixins.page-width();

    margin: 135px auto;
    position: relative;
    text-align: center;

    .btn {
        position: relative;
        z-index: 2;
    }

    &::before {
        background: $color-gray-5;
        content: '';
        height: 2px;
        left: 70px;
        position: absolute;
        right: 70px;
        top: 36px;
    }
}

.btn-large-box {
    @media (max-width: 980px) {
        margin-bottom: 80px;
        margin-top: 80px;
    }

    @media (max-width: 766px) {
        margin-bottom: 50px;
        margin-top: 50px;

        &::before {
            left: 0;
            right: 0;
        }

        @media (max-width: 600px) {
            &::before {
                left: -30px;
                right: -30px;
            }
        }

        @media (max-width: 440px) {
            font-size: 18px;
            padding-left: 24px;
            padding-right: 24px;

            &::before {
                left: -9px;
                right: -9px;
            }
        }
    }
}

.btn-large-box-background {
    margin-bottom: 235px; // - padding-bottom content
    margin-top: 230px;
    position: relative;

    @media (max-width: 980px) {
        margin-bottom: 180px;
        margin-top: 150px;
    }

    @media (max-width: 630px) {
        margin-bottom: 150px;
        margin-top: 160px;
    }

    &::after {
        background: url('../img/bg-sprinkles.svg') center center no-repeat;
        bottom: -126px;
        content: '';
        height: 341px;
        left: 50%;
        margin-left: -275px;
        max-width: 550px;
        position: absolute;
        width: 100%;

        @media (max-width: 630px) {
            left: 10%;
            margin-left: 0;
            max-width: 80%;
            right: 0;
        }

        @media (max-width: 500px) {
            transform: scale(1.2);
        }
    }
}

.btn-secondary {
    background: $color-secondary;
    color: $color-white;
    font-size: 18px;
    padding-bottom: 8px;
    padding-top: 8px;
    text-transform: none;
}

.image-block {
    width: 100%;

    @media (max-width: 600px) {
        max-width: 50%;
        text-align: left;
    }

    img {
        height: auto;
        max-width: 300px;
    }

    &:empty {
        background: $empty;
        display: inline-block;
        height: 300px;
        max-width: 300px;
        width: 100%;

        @media (max-width: 600px) {
            height: 250px;
            max-width: 50%;
        }
    }
}

.icon-block {
    height: auto;
    margin-bottom: 35px;
    width: 100%;

    img {
        height: auto;
        max-width: 70px;
    }

    &:empty {
        background: $color-gray-14;
        display: inline-block;
        height: 70px;
        max-width: 70px;
        width: 100%;
    }
}

.removemargin {
    margin-bottom: 0;
}

.prev-page-link {
    background: url('../img/icon-back.svg') center center no-repeat;
    display: inline-block;
    height: 36px;
    margin-right: 30px;
    position: relative;
    top: 8px;
    width: 36px;
}

.img-max-width {
    img {
        max-width: 100%;
    }
}

.container {
    @media (min-width: 1200px) {
        max-width: 1000px;
        padding: 0 30px;
    }

    @media (max-width: 575px) {
        max-width: 90%;
    }
}

.mb-xxl {
    margin-bottom: 130px;

    @media (max-width: 980px) {
        margin-bottom: 65px;
    }

    @media (max-width: 767px) {
        margin-bottom: 35px;
    }
}

.display-mobile {
    display: none;

    @media (max-width: 766px) {
        display: block;
    }
}

.display-desktop {
    display: block;

    @media (max-width: 766px) {
        display: none;
    }
}

.btn.display-mobile {
    &:hover,
    &:focus {
        color: $color-black;
    }
}

.width-xxl {
    .container {
        max-width: 103em;
    }
}

.width-xl {
    .container {
        max-width: 87.5em;
    }
}

.h-2-lines {
    min-height: 3.3em;
}

.img-border {
    border: 1px solid $color-gray-9;
}

.mw-19em {
    max-width: 19em;
}

.content .table-light {
    tr {
        border-bottom: 1px solid $color-gray-7;

        &:last-child {
            border-bottom: 0;
        }
    }

    td {
        background-color: $color-white;
        border-bottom: 0;
        padding-left: 0;
    }
}

@media (max-width: 600px) {
    .content table.table-light {
        max-width: 100%;

        td {
            text-align: left;

            &:last-child {
                background-color: $color-white;
                border-bottom: 0;
            }
        }
    }
}

.m-w-100 {
    max-width: 100%;

    * {
        max-width: 100%;
    }
}

.font-weight-5 * {
    font-family: $font-medium;
}

.back-arrow {
    background: url('../img/icon-back.svg') center center no-repeat;
    background-size: contain;
    flex-shrink: 0;
    height: 36px;
    width: 36px;
}

.content-margin {
    margin-top: -80px;

    @media (max-width: 730px) {
        margin-top: -30px;
    }
}

.content {
    .m-0-child * {
        margin: 0;
    }

    .color-primary * {
        color: $color-primary;
    }
}

.content .content {
    padding: 0;
}

.cznic-cookiebar-root-position-bottom {
    bottom: 51px !important;

    @media (min-width: 767px) {
        bottom: 0 !important;
        transform: translateX(0) !important;
        width: calc(100% - 385px) !important;
    }

    @media (min-width: 1351px) {
        left: 0 !important;
    }
}

// Temporarly fix until the styles are adapted to the new Bootstrap 5.
.offset-sm-2 {
    margin-left: 0;
}

@media (min-width: 768px) {
    .offset-md-1 {
      margin-left: auto;  // 8.33333333%;
    }
}

@media (min-width: 768px) {
    .pr-md-0, .px-md-0 {
      padding-right: 0 !important;
    }
}

@media (min-width: 768px) {
    .pl-md-0, .px-md-0 {
      padding-left: 0 !important;
    }
}
