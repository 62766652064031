/* blocks */
.blocks {
    position: relative;

    .row {
        margin-bottom: 150px;

        @media (max-width: 980px) {
            margin-bottom: 100px;
        }

        @media (max-width: 767px) {
            margin-bottom: 50px;
        }

        p {
            margin: 0 0 18px;
            max-width: 410px;
        }
    }

    img {
        margin: 10px auto;
    }

    .reverse-order .image-block {
        figure {
            @media (min-width: 992px) {
                text-align: right;
            }
        }
    }
}
