@use '../definitions' as *;
@use '../mixins';

/* support */
.support {
    margin-top: -10px;

    .sub-title {
        @include mixins.bottom-box();

        margin: 0 0 130px;
    }

    .collapse-box {
        margin-bottom: 130px;

        @media (max-width: 980px) {
            margin-bottom: 50px;
            margin-top: 40px;
        }

        @media (max-width: 730px) {
            margin-bottom: 25px;
            margin-top: 25px;
        }
    }

    .top-title {
        .bottom-line {
            display: block;
        }
    }
}

.support-list {
    @include mixins.flex-row;

    align-items: inherit;
    margin: auto;
    margin-bottom: 130px - 80px; /* removes margin-bottom support-item */
    max-width: 880px;

    @media (max-width: 1015px) {
        justify-content: center;
    }

    a {
        @include mixins.flex-col(25%);

        background: url('../img/bg-support.svg') no-repeat;
        background-size: contain;
        color: $color-primary;
        font-family: $font-semi-bold;
        height: 110px;
        line-height: 1.4;
        margin: 0 10px 40px;
        max-width: 200px;
        padding: 20px;
        text-align: left;
        width: 20vw;

        @media (max-width: 900px) {
            margin: 0 20px 40px;
            max-height: 170px;
            max-width: 170px;
        }

        @media (max-width: 820px) {
            @include mixins.flex-col(33.333%);

            height: 150px;
            margin: 0 20px;
            max-height: 200px;
            max-width: 200px;
            padding: 20px;
            width: 30vw;
        }

        @media (max-width: 730px) {
            @include mixins.flex-col(50%);

            height: 20vw;
            margin: 0 15px 15px;
            max-height: 200px;
            max-width: 200px; /* overwritting flex-col mixin */
            padding: 20px; /* overwritting flex-col mixin */
            width: 30vw;
        }

        @media (max-width: 500px) {
            background-position: bottom;
            background-size: cover;
            height: 100px;
            margin-bottom: 30px;
            width: 100%;
        }

        &:hover {
            background: url('../img/bg-support-hover.svg') no-repeat;
            background-size: contain;
            color: $color-white;

            @media (max-width: 500px) {
                background-position: bottom;
                background-size: cover;
                height: 100px;
                width: 100%;
            }
        }
    }
}
