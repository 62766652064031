/* widgets login with socials buttons */

.btn-login-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: -30px 0 140px;
    text-align: center;

    @media (max-width: 1010px) {
        flex-direction: column;
        flex-wrap: wrap;
    }

    @media (max-width: 730px) {
        margin: 0 0 100px;
    }

    a {
        margin: 0 8px;

        @media (max-width: 1010px) {
            margin: 10px auto;
            white-space: normal;
            width: 80%;
        }

        @media (max-width: 630px) {
            width: 90%;
        }
    }
}
