@use '../definitions' as *;
@use '../mixins';

/* blocks down */
.blocks-down {
    padding-top: 15px;
    position: relative;

    .block {
        @include mixins.bottom-box();

        margin-bottom: 76px;

        p {
            max-width: none;
        }
    }

    .block-last {
        margin-bottom: 80px;
        padding-top: 80px;
        position: relative;

        &::before {
            background: $color-gray-5;
            content: "";
            height: 2px;
            left: -100px;
            position: absolute;
            right: -100px;
            top: 0;

            @media (max-width: 980px) {
                left: 0;
                right: 0;
            }
        }
    }

    .container {
        max-width: 730px;
    }

    .image-block {
        img {
            max-width: 150px;
        }

        &:empty {
            height: 150px;
            max-width: 150px;
        }
    }

    @media (max-width: 600px) {
        padding-bottom: 15px;

        .block {
            margin-bottom: 10px;
        }

        .row {
            margin-bottom: 40px;
        }

        .btn-large-box {
            margin-top: -30px;
            top: 50px;
        }
    }
}
