/* header tophead-mobile */

.tophead-mobile {
    display: none;

    @media (max-width: 766px) {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 0.6em 0;
    }

    .mobile-search {
        box-sizing: content-box;
        height: 1em;
        padding: 1em 30px;
        width: 1em;

        @media (max-width: 440px) {
            padding: 1em 1.6em;
        }
    }

    .mobile-search {
        height: 1em;
        width: 1em;
    }

    .logo {
        img {
            height: 1.6em;
        }
    }
}

.show-nav {
    height: 48px;
    width: 67px;

    > img {
        box-sizing: content-box;
        height: 1em;
        padding: 1em 30px;
        width: 1em;

        @media (max-width: 440px) {
            padding: 1em 1.6em;
        }
    }

    .icon-close {
        opacity: 0;
        transform: scale(0);
        transition: 0.15s ease-in-out;
    }

    .icon-open {
        opacity: 1;
        transform: scale(1);
        transition: 0.15s ease-in-out;
    }

    &.active {
        .icon-close {
            opacity: 1;
            transform: scale(1);
        }

        .icon-open {
            opacity: 0;
            transform: scale(0);
        }
    }
}
