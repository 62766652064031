@use '../definitions' as *;

/* subheader */

.subheader {
    h1 {
        color: $color-white;
        margin-bottom: 15px;
        margin-top: -20px;

        @media (max-width: 980px) {
            font-size: 38px;
            margin-top: 0;
        }

        @media (max-width: 440px) {
            font-size: 24px;
        }
    }

    p {
        color: $color-white;
        margin: 0 0 15px;
        max-width: 400px;

        @media (max-width: 440px) {
            font-size: 16px;
            margin-bottom: 20px;
        }
    }

    .btn-primary {
        margin-top: 25px;
    }
}

.content-hp {
    padding-top: 0 !important;

    .slide-box {
        margin-top: 140px;

        @media (max-width: 980px) {
            margin-top: 140px;
        }

        @media (max-width: 730px) {
            margin-top: 50px;
        }

        .stats {
            @media (max-width: 980px) {
                margin-top: 0;
            }
        }
    }
}

.provider-img-over img {
    transform: translate(-17%, 0);
    width: 130%;
}

.user-img-over img {
    transform: translate(-9%, 0);
    width: 103%;
}
