@use '../definitions' as *;

/* blocks gray */

.blocks-gray {
    background: $color-gray-3;
    margin-bottom: 96px;
    padding-bottom: 1px;
    padding-top: 96px;
    position: relative;

    p {
        max-width: 410px;
    }

    .row {
        @media (min-width: 721px) {
            margin-bottom: 60px;
            position: relative;

            &:first-of-type::before {
                background: $color-white;
                bottom: 0;
                content: '';
                height: 2px;
                left: 0;
                margin: auto;
                position: absolute;
                right: 0;
            }
        }

        > div {
            margin-bottom: 50px;
        }
    }
}
