@use '../definitions' as *;

// /*tabs content*/

.tab-content {
    img {
        width: 180px;

        @media (max-width: 767px) {
            display: block;
            margin: auto auto 1em;
            width: 70%;
        }
    }

    .row {
        margin-bottom: 45px;
    }
}

.divider {
    border-top: 2px solid $color-gray-5;
    margin-bottom: 40px;
    position: relative;
    top: -45px;
}

.arrow-down {
    margin: 0;
    position: relative;
    text-align: center;
    z-index: 2;

    img {
        height: 60px;
        width: 60px;
    }
}

.nav-pills .nav-item {
    @media (max-width: 440px) {
        display: block;
        width: 100%;
    }

    a {
        background: $color-gray-3;
        border-radius: 0;
        color: $color-black;
        float: left;
        font-family: $font-semi-bold;
        padding: 2px 24px;
        position: relative;
        text-decoration: none;

        @media (max-width: 900px) {
            font-size: 16px;
            padding-left: 12px;
            padding-right: 12px;
        }

        @media (max-width: 440px) {
            float: none;
            text-align: center;
            width: 100%;
        }

        &::before {
            display: none;
        }

        &.active::before {
            border-color: transparent transparent $color-white transparent;
            border-style: solid;
            border-width: 0 5px 5px;
            bottom: -1px;
            content: '';
            display: block;
            height: 0;
            left: 50%;
            margin-left: -5px;
            position: absolute;
            width: 0;
        }

        &.active {
            background: $color-primary;
            color: $color-white;
        }
    }
}
