@use '../definitions' as *;

/* matalog logos */

.logos {
    background: $color-gray-3;
    padding: 80px 0 0;

    @media (max-width: 630px) {
        padding-bottom: 40px;
    }

    .main {
        flex-wrap: wrap;
        justify-content: center;
    }

    .logo {
        margin: 0 0 80px;
        padding: 0 10px;
        text-align: center;
        width: 25%;

        @media (max-width: 630px) {
            margin-bottom: 40px;
            width: 33.33%;
        }

        @media (max-width: 440px) {
            width: 50%;
        }
    }
}
