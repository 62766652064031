@use '../definitions' as *;
@use '../mixins';

/* widgets contact */

.contact-wrap {
    @include mixins.bottom-box();

    margin-bottom: 140px;
}

.contact-box {
    align-items: stretch;
    background: $color-gray-3;
    margin-bottom: 132px;
    margin-left: 0;
    margin-right: 0;

    > div {
        padding: 0;
    }

    .contact-col {
        background: url('../img/support-contact-corner.svg') center bottom no-repeat;
        background-size: contain;
        padding: 25px 30px 60px;

        @media (max-width: 780px) {
            @include mixins.flex(0 0 100%);

            max-width: 100%; /* might be needed because of flex browser interpretations */
            padding-bottom: 140px;
            width: 100%;
        }

        @media (max-width: 370px) {
            padding-bottom: 100px;
        }

        p {
            a {
                display: block;
            }
        }
    }
}

.contact-map {
    @include mixins.flex(1);

    @media (max-width: 580px) {
        @include mixins.flex(0 0 100%);

        max-width: 100%; /* might be needed because of flex browser interpretations */
        width: 100%;
    }

    iframe {
        max-width: 100%;
        width: 100%;
    }
}

.contact-info {
    margin-left: auto;
    margin-right: auto;
    max-width: 670px;
}

.contact-person {
    margin-bottom: 100px;

    img {
        display: block;
        margin: auto 30px auto auto;

        @media (max-width: 980px) {
            margin: auto;
        }
    }

    @media (max-width: 575px) {
        .row {
            display: block;
        }

        .col {
            text-align: center;
        }
    }
}

.contact-provider {
    margin-bottom: 85px;

    .contact-info,
    .contact-box {
        margin-bottom: 80px;
    }

    .row {
        align-items: inherit;
    }
}

blockquote {
    border-left: 0;
    font-size: inherit;
    margin: 0;
    padding: 0;
}
