@use '../../definitions' as *;

/* header/navigation mainnav */

.mainnav {
    background-color: $color-gray;
    margin: 1em 0.8em 0;
    transition: 0.15s ease-in-out;

    // controller for homepage
    @media (max-width: 766px) {
        margin: 0;
        opacity: 0;
        padding: 0 1.5em;
        position: absolute;
        top: 50px;
        transform: scaleY(0);
        transform-origin: top;
        width: 100vw;
    }

    &.active {
        @media (max-width: 766px) {
            opacity: 1;
            transform: scaleY(1);
        }
    }

    a {
        color: $color-white;
        font-size: 15px;

        &:not([href]):not([tabindex]) {
            color: $color-white;
        }
    }

    .search-icon {
        width: 40px;
    }
}

// contoler for another pages
.tophead-box.menu-open .mainnav {
    @media (max-width: 766px) {
        opacity: 1;
        padding: 0;
        position: relative;
        top: 0;
        transform: scaleY(1);
        width: 100%;
    }
}

.tophead-box.menu-open {
    background-color: $color-gray;
    transition: 0.15s ease-in-out;

    @media (max-width: 766px) {
        opacity: 0;
        position: absolute;
        transform: scaleY(0);
        transform-origin: top;
        width: 100%;
    }

    &.active {
        @media (max-width: 766px) {
            opacity: 1;
            transform: scaleY(1);
        }
    }
}
