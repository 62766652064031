@use '../definitions' as *;

/* header tophead-box */

.tophead-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;

    @media (max-width: 766px) {
        flex-direction: column-reverse;
    }

    .logo {
        width: 8.3em;

        @media (max-width: 890px) {
            width: 10%;
        }

        img {
            width: 100%;
        }
    }

    .mojeid-btn {
        display: flex;
        flex-direction: column;
    }

    @media (max-width: 766px) {
        .logo {
            display: none;
        }

        .top {
            width: 100%;
        }

        .mojeid-btn {
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 1.3em;
            width: 100%;

            a {
                margin: 0 0.5em 0 0;
                width: 50%;

                &:last-of-type {
                    margin: 0 0 0 0.5em;
                }
            }
        }
    }

    .btn {
        border-radius: 2px;
        font-size: 12.5px;
        letter-spacing: 0;
        line-height: 1;
        margin: 4px 0;
        min-width: 10.4em;
        padding: 7px 1em 6px;

        @media (max-width: 766px) {
            padding: 9px 1em 8px;
        }

        &--create-mojeid {
            background: none;
            border: 1px solid $color-gray-12;
            color: $color-gray-12;

            &:hover {
                background-color: $color-gray-12;
                color: $color-gray;
            }
        }
    }
}
