@use '../definitions' as *;

/* footer */

footer ul.logo {
    margin: 0;
    padding: 0;

    li {
        list-style-type: none;
    }
}

.contact {
    border-top: 1px solid $color-gray-6;
    padding: 50px 0;
    text-align: center;

    @media (max-width: 600px) {
        text-align: left;
        width: 100%;
    }

    @media (max-width: 440px) {
        border: 1;
        border-bottom: 1px solid $color-gray-6;
        margin-bottom: 30px;
        padding: 40px 0;
    }

    a {
        color: $color-gray-3;
        font-family: $font-bold;
        font-size: 32px;
        margin: 0;
        padding: 0;
        text-decoration: none;

        @media (max-width: 800px) {
            display: block;
            font-size: 28px;
        }

        @media (max-width: 440px) {
            font-size: 22px;
        }

        &:hover {
            color: $color-gray-11;
            cursor: pointer;
        }
    }

    figure {

        display: inline;
        margin: 0 50px;
        position: relative;
        top: -5px;
        @media (max-width: 800px) {
            display: none;
        }
    }
}

.bottom {
    background: $color-gray;
    padding-top: 78px;

    .logo {
        height: 30px;
        left: 30px;
        margin: 0;
        position: absolute;
        top: 10px;
        width: 100px;
        z-index: 1;

        @media (max-width: 730px) {
            display: block;
            left: 0;
            margin-top: 5px;
            position: relative;
            top: -50px;
            width: 100%;
        }

        li.lang {
            a {
                color: $color-gray-11;
                font-size: 16px;
                position: relative;
                text-decoration: none;
                top: 8px;

                @media (max-width: 980px) {
                    font-size: 15px;
                }
            }

            &:hover {
                a {
                    color: $color-white;
                    text-decoration: none;
                }
            }

            &.active {
                display: none;
            }
        }
    }

    ul.logo {
        top: 40px;

        @media (max-width: 980px) {
            display: block;
        }

        @media (max-width: 730px) {
            margin-top: -50px;
            top: 0;
        }
    }

    .main {
        @media (max-width: 600px) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .copy {
        margin-bottom: 50px;
        padding-left: 232px;

        @media (max-width: 980px) {
            padding-left: 150px;
        }

        @media (max-width: 730px) {
            padding-left: 0;
        }

        @media (max-width: 440px) {
            order: 2;
        }

        p {
            color: $color-gray-4;
            font-size: 12px;
            line-height: 1.3;
        }
    }
}
