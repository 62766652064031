@use '../definitions' as *;

.niakampf-hp {
    .niakampf-btn {
        .btn-primary {
            background-color: $color-black;
            border: 1px solid $color-black;
            margin-top: 0.7em;
            padding-right: 2.4em;
            position: relative;

            &::before {
                background-image: url('../img/ID_symbol_button.svg');
                background-repeat: no-repeat;
                bottom: 0;
                content: '';
                display: block;
                height: 33px;
                margin: auto;
                position: absolute;
                right: 8px;
                top: 0;
                width: 33px;
            }

            &:hover,
            &:focus,
            &:active:focus {
                background-color: $color-gray-10;
                border: 1px solid $color-gray-10;
                box-shadow: none;
            }
        }

        .btn-link {
            display: block;
            font-family: $font-regular;
            font-size: 1.25em;
            margin: 0.5em 0;
            padding: 0;
            text-align: left;
            text-decoration: underline;
        }
    }
}
