@use '../definitions' as *;
@use '../mixins';

/* widgets slide box */

.slide-box {
    @include mixins.bottom-box();

    margin: 140px 0 125px;

    @media (max-width: 980px) {
        width: 100vw;
    }
}

.slide {
    overflow: hidden;
    position: relative;
    width: 100%;

    .image,
    .text {
        @include mixins.flex-col(50%);
    }

    .text {
        margin: 0 auto;

        @media (max-width: 730px) {
            @include mixins.flex-col(70%);
        }

        @media (max-width: 600px) {
            @include mixins.flex(0 0 100%);

            max-width: 100%;
        }
    }

    .image {
        padding-bottom: 46px;
        position: relative;
        text-align: center;

        @media (max-width: 730px) {
            @include mixins.flex-col(30%);

            padding-bottom: 46px;
        }

        @media (max-width: 600px) {
            @include mixins.flex(0 0 100%);

            margin-bottom: 30px;
            max-width: 100%;
            padding-bottom: 46px;
            text-align: left;
        }

        &::before {
            background: url('../img/reference-with-shadow.svg') center center no-repeat;
            bottom: 0;
            content: "";
            height: 30px;
            left: 50%;
            margin-left: -100px;
            position: absolute;
            width: 200px;

            @media (max-width: 730px) {
                left: 15px;
                margin-left: 0;
                right: 15px;
                width: auto;
            }

            @media (max-width: 600px) {
                width: 200px;
            }
        }

        img {
            height: auto;
            max-width: 250px;
            width: 100%;

            @media (max-width: 600px) {
                max-width: 200px;
            }
        }
    }
}

.slide-controls {
    text-align: center;

    a {
        display: inline-block;
        height: 35px;
        position: relative;
        width: 35px;

        &::before {
            background: #d9dee2;
            border-radius: 100%;
            content: "";
            height: 10px;
            left: 50%;
            margin-left: -5px;
            margin-top: -5px;
            position: absolute;
            top: 50%;
            transition: 0.4s;
            width: 10px;
        }

        &:hover {
            &::before {
                background: $color-gray;
            }
        }

        &.active {
            &::before {
                background: $color-primary;
            }
        }
    }
}
