@use '../mixins';

//Display - font size for child's
$minwidth: 19rem;
$maxwidth: 48rem;

.display-xl,
.display-xl * {
    @include mixins.fluid-type($minwidth, $maxwidth, 2.7rem, 3.375rem);

    line-height: 1.2;
}

.display-1,
.display-1 * {
    @include mixins.fluid-type($minwidth, $maxwidth, 2.3rem, 3rem);

    line-height: 1.2;
}

.display-2,
.display-2 * {
    @include mixins.fluid-type($minwidth, $maxwidth, 1.8rem, 2.5rem);

    line-height: 1.2;
}

.display-3,
.display-3 * {
    @include mixins.fluid-type($minwidth, $maxwidth, 1.3rem, 2rem);

    line-height: 1.2;
}

.display-4,
.display-4 * {
    @include mixins.fluid-type($minwidth, $maxwidth, 1rem, 1.5rem);

    line-height: 1.2;
}

.display-5,
.display-5 * {
    font-size: 1.25rem;
    line-height: 1.2;
}

.display-6,
.display-6 * {
    font-size: 1rem;
    line-height: 1.2;
}
