@use '../definitions' as *;

/* blocks bottom */
.blocks-bottom {
    background: $color-gray-3;
    padding: 50px 0;

    .row {
        align-items: inherit;
    }
}
