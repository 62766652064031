@use '../definitions' as *;
@use '../mixins';

/* widgets video */

.video-box {
    @include mixins.bottom-box();

    border: 3px solid $color-white;
    box-shadow: 0 0 20px 2px $empty;
    box-sizing: content-box;
    cursor: pointer;
    height: 0;
    margin: 0 auto;
    margin-bottom: 100px;
    max-width: 942px;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;

    @media (max-width: 600px) {
        margin-left: -30px;
        margin-right: -30px;
        width: auto;
    }

    @media (max-width: 440px) {
        margin-left: -9px;
        margin-right: -9px;
    }

    /* 16:9 */
    iframe {
        border: 0;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
