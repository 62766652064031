@use '../definitions' as *;

/* footer navigation */

.bottomnav {
    display: table;
    margin-bottom: 40px;
    padding-left: 232px - 40px;
    width: 100%;

    @media (max-width: 980px) {
        margin-top: 10px;
        padding-left: 150px - 40px;
    }

    @media (max-width: 730px) {
        margin-top: 25px;
        padding-left: 0;
    }

    @media (max-width: 600px) {
        margin-top: 0;
    }
}

.bottomnav-list {
    display: block;
    float: left;
    padding-left: 40px;
    padding-right: 10px;
    position: relative;
    width: 33.33%;

    @media (max-width: 730px) {
        padding: 0 20px;
    }

    @media (max-width: 600px) {
        border-top: 1px solid $color-gray-6;
        padding: 0;
        padding-top: 20px;
        width: 100%;
    }

    &::before {
        background: $color-gray-6;
        bottom: 6px;
        content: "";
        position: absolute;
        right: 0;
        top: 6px;
        width: 1px;

        @media (max-width: 600px) {
            display: none;
        }
    }

    &:first-child {
        @media (max-width: 730px) {
            padding-left: 0;
        }

        @media (max-width: 600px) {
            border-top: 0;
            margin-top: 0;
            padding-top: 0;
        }
    }

    &:last-child {
        @media (max-width: 730px) {
            padding-right: 0;
        }

        &::before {
            display: none;
        }
    }

    p {
        margin: 0;
    }

    a {
        color: $color-gray-11;
        display: block;
        font-size: 16px;
        line-height: 1.2;
        padding: 3px 0;
        text-decoration: none;

        @media (max-width: 980px) {
            font-size: 15px;
        }

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }
}
