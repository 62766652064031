@use '../../definitions' as *;

/* header/navigation mainnav-select */

.mainnav-select {
    align-items: flex-end;
    border-bottom: 1px solid $color-gray-6;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.1em;

    @media (max-width: 766px) {
        border-top: 1px solid $color-gray-6;
        flex-wrap: wrap;
        margin: auto;
        padding-bottom: 0.9em;
        position: relative;
        text-align: center;
    }

    a {
        border: 0;
        color: $color-gray-4;
        font-size: 0.75em;
        line-height: 1;
        padding: 1.7em 9px 0;
        position: relative;
        text-transform: uppercase;

        @media (max-width: 766px) {
            display: inline-block;
            font-size: 1em;
            padding: 1.1em 0 0;

            &:nth-child(odd) {
                text-align: left;
            }

            &:nth-child(even) {
                text-align: right;
            }
        }

        &::before {
            background: $color-gray-6;
            content: '';
            height: 12px;
            position: absolute;
            right: 0;
            top: 20px;
            width: 1px;

            @media (max-width: 766px) {
                display: none;
            }
        }

        &:hover {
            color: $color-gray-13;
        }

        &.active {
            color: $color-white;
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            &::before {
                display: none;
            }
        }
    }

    .language-chooser {
        display: none;
    }

    @media (min-width: 766px) {
        .language-chooser {
            display: block;
        }
    }
}

.language-chooser {
    margin: 0;
    padding: 0;

    li {
        font-size: 1em;
        line-height: 1;
        list-style-type: none;
    }

    .lang.active {
        display: none;;
    }

    a {
        padding: 0;
        padding-top: 1.7em;
        text-decoration: none;
    }
}

.mainnav-for {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (min-width: 766px) {
        justify-content: flex-start;
    }
}
