@use '../definitions' as *;
@use '../mixins';

/* content */

.content-404 {
    background-image: url('../img/bg-404.svg');
    background-size: cover;
    margin-top: -140px;
    padding-top: 140px;

    h1 {
        color: $color-primary;
    }
}

.content {
    padding-top: 140px;

    @media (max-width: 980px) {
        padding-top: 100px;
    }

    @media (max-width: 730px) {
        padding-top: 70px;
    }

    article {
        @include mixins.bottom-box();

        margin-bottom: 130px;
        margin-left: auto;
        margin-right: auto;
        max-width: 700px;
    }

    h1,
    h2,
    h3 {
        color: $color-gray;
        font-size: 22px;
        margin: 0 0 20px;

        &.hl {
            color: $color-primary;
        }
    }

    h1 {
        font-size: 32px;
    }

    p {
        line-height: 1.85;
        margin: 0 0 18px;

        @media (max-width: 730px) {
            font-size: 16px;
        }
    }

    ul {
        margin-bottom: 18px;
    }

    ol {
        counter-reset: item;
        list-style-type: none;
        margin: 0;
        margin-bottom: 45px;
        padding: 0;

        li {
            counter-increment: item;
            display: table;
            margin: 0 0 18px;

            &::before {
                color: $color-gray-2;
                content: counters(item, '.') '. ';
                display: table-cell;
                padding-right: 0.6em;
            }
        }

        ol {
            margin-bottom: 0;
            margin-top: 18px;

            li {
                &::before {
                    content: counters(item, '.') ' ';
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    table {
        margin-bottom: 20px;
        width: 100%;

        @media (max-width: 600px) {
            margin-left: auto;
            margin-right: auto;
            max-width: 400px;
        }

        .icon,
        .icon-yes {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            display: inline-block;
            height: 20px;
            width: 20px;
        }

        .icon-yes {
            background-image: url('../img/icon-checkbox.svg');
        }

        .hl {
            background: $color-secondary;
            border: 0;
            color: $color-white;

            .icon-yes {
                background-image: url('../img/icon-checkbox-white.svg');
            }
        }

        th {
            @media (max-width: 600px) {
                font-family: $font-regular;
                font-size: 22px;
            }
        }

        td,
        th {
            border-bottom: 2px solid $color-white;
            line-height: 1.5;
            padding: 8px 20px;

            @media (max-width: 600px) {
                border: 0;
                display: block;
                font-size: 18px;
                line-height: 1.2;
                padding-bottom: 6px;
                padding-top: 6px;
                text-align: center;

                &:first-child {
                    padding-top: 14px;
                }

                &:last-child {
                    background: $color-gray-3;
                    border-bottom: 2px solid $color-white;
                    padding-bottom: 14px;
                }
            }
        }

        thead {
            background-color: $color-gray-5;

            th {
                font-size: 18px;

                &.hl {
                    border-bottom: 2px solid $color-white !important;
                }
            }
        }

        td {
            background: $color-gray-3;

            @media (max-width: 730px) {
                font-size: 16px;
            }

            @media (max-width: 600px) {
                strong {
                    font-family: $font-regular;
                }

                &:first-child {
                    background-color: $color-white;
                    font-family: $font-semi-bold;

                    strong {
                        font-family: $font-semi-bold;
                    }
                }

                &:empty {
                    &::before {
                        content: '-';
                    }
                }
            }

            .hl {
                border-color: $color-secondary;

                @media (max-width: 600px) {
                    background: $color-gray-3;
                    color: $color-secondary;
                    font-family: $font-semi-bold;

                    .icon-yes {
                        background-image: url('../img/icon-checkbox.svg');
                    }
                }
            }
        }

        td.hl {
            @media (max-width: 600px) {
                border-color: $color-white;
            }
        }
    }

    .title,
    .top-title {
        color: $color-primary;
        font-family: $font-bold;
        font-size: 32px;
        margin-bottom: 150px;
        margin-top: 0;
        text-align: center;
    }

    .title {
        @media (max-width: 630px) {
            font-size: 26px;
        }

        br {
            @media (max-width: 630px) {
                display: none;
            }
        }
    }

    .top-title-index {
        color: $color-gray-14;
        padding-top: 60px;
    }

    .list-title {
        color: $color-primary;
        font-size: 22px;
        margin-top: 50px;

        &::before {
            color: $color-primary;
            font-family: $font-bold;
        }

        &:first-child {
            margin-top: 0;
        }

        h1,
        h2,
        h3 {
            color: $color-primary;
            display: inline;
            margin-bottom: 0;
        }
    }

    .top-title {
        margin-bottom: 30px;
        margin-top: -80px;

        @media (max-width: 980px) {
            margin-bottom: 50px;
        }

        @media (max-width: 730px) {
            margin-top: -30px;
        }

        .bottom-line {
            display: inline-block;
            margin-bottom: 80px;
            position: relative;

            @media (max-width: 730px) {
                margin-bottom: 35px;
            }

            &::before {
                background: $color-gray-5;
                bottom: -40px;
                content: '';
                height: 1px;
                left: 0;
                position: absolute;
                right: 0;

                @media (max-width: 980px) {
                    bottom: -40px;
                }

                @media (max-width: 730px) {
                    bottom: -20px;
                }
            }
        }
    }

    .sub-title {
        // text-align: center;
        font-size: 22px;
        margin-bottom: 0;
        margin-top: 110px;

        @media (max-width: 980px) {
            margin-top: 50px;
        }

        @media (max-width: 730px) {
            font-size: 16px;
        }

        p {
            margin: 0;
        }

        a {
            font-size: inherit;

            @media (max-width: 630px) {
                font-size: 18px;
            }
        }

        span {
            display: inline-block;
        }
    }

    .sub-title-0topmargin {
        margin-top: 0;
    }

    .row-valid {
        @media (max-width: 630px) {
            margin: 0;

            .block {
                margin-bottom: 35px;
            }

            .row {
                margin-bottom: 0;
            }
        }
    }
}
