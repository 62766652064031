@use '../mixins';

/*  widgets map */

.map-detail {
    @include mixins.bottom-box();

    margin-bottom: 150px;

    .select-detail {
        margin-top: -8px;
    }
}
