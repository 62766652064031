@use '../mixins';

//Title h 1-6 size
$minwidth: 19rem;
$maxwidth: 48rem;

h1 {
    @include mixins.fluid-type($minwidth, $maxwidth, 2.3rem , 3rem );

    line-height: 1.2;
}

h2 {
    @include mixins.fluid-type($minwidth, $maxwidth, 1.8rem , 2.5rem );

    line-height: 1.2;
}

h3 {
    @include mixins.fluid-type($minwidth, $maxwidth, 1.3rem , 2rem );

    line-height: 1.2;
}

h4 {
    @include mixins.fluid-type($minwidth, $maxwidth, 1rem , 1.5rem );

    line-height: 1.2;
}

h5 {
    font-size: 1.25rem;
    line-height: 1.2;
}

h6 {
    font-size: 1rem;
    line-height: 1.2;
}
