@use '../definitions' as *;
@use '../mixins';

/* matalog number navigation */
.number-nav {
    @include mixins.flex-row;

    background: $color-gray-3;
    justify-content: center;
    padding: 0 20px;
    text-align: center;

    a {
        font-size: 22px;
        height: 60px;
        line-height: 60px;
        min-width: 42px;
        padding: 0 5px;
        text-align: center;

        @media (max-width: 820px) {
            font-size: 18px;
            min-width: 30px;
        }

        span {
            display: inline-block;
            position: relative;
            transition: 0.4s;

            &::before,
            &::after {
                background: $color-gray-5;
                content: '';
                height: 4px;
                left: 0;
                opacity: 0;
                position: absolute;
                right: 0;
                top: 0;
                transition: 0.4s;
            }

            &::after {
                bottom: 0;
                top: auto;
            }
        }

        &:hover {
            span {
                &::before,
                &::after {
                    opacity: 1;
                }
            }
        }

        &.active {
            color: $color-primary;

            span {
                &::before,
                &::after {
                    opacity: 1;
                }
            }
        }
    }

    .next,
    .prev {
        background: url('../img/number-nav-arrow.svg') center center no-repeat;
        background-size: 16px 16px;
        height: 42px;
        width: 60px;
    }

    .next {
        transform: rotate(-90deg);
    }

    .prev {
        transform: rotate(90deg);
    }
}
