@use '../definitions' as *;
@use '../mixins';

// /* search */

.search-wrap {
    background: $color-gray;
    display: table;
    max-height: 0;
    position: relative;
    transition: 0.4s;
    width: 100%;
    z-index: 2;
}

.search-box {
    left: 0;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
    z-index: 2;

    .main {
        padding-left: 190px + 30px;
        position: relative;
        z-index: 2;

        @media (max-width: 980px) {
            padding-right: 70px;
        }

        @media (max-width: 730px) {
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    .bg {
        background: rgba(0, 0, 0, 0.7);
        bottom: 0;
        content: '';
        left: 0;
        max-height: 0;
        opacity: 0;
        position: fixed;
        right: 0;
        top: 0;
    }

    &.active {
        max-height: 1000px;
        opacity: 1;

        .search-wrap {
            display: flex;
            max-height: 1000px;
            min-height: 115px;
            padding-bottom: 17px;

            @media (max-width: 730px) {
                padding-top: 30px;
            }
        }

        .bg {
            max-height: 100%;
            opacity: 1;
        }
    }
}

.search-close {
    background: url('../img/icon-close.svg') center center no-repeat;
    background-size: 36px 36px;
    height: 50px;
    opacity: 0.3;
    position: absolute;
    right: -78px;
    top: -2px;
    width: 50px;

    @media (max-width: 980px) {
        right: -60px;
    }

    @media (max-width: 730px) {
        right: -15px;
        top: -50px;
    }

    &:hover {
        opacity: 1;
    }
}

.search-list {
    max-width: 675px;
    padding-top: 7px;

    p.search-summary {
        color: $color-gray-2;
        font-size: 12px;
        margin-bottom: 0;
    }

    a {
        color: $color-white;
        display: block;
        font-family: $font-semi-bold;
        font-size: 16px;
        padding: 8px 0;

        @media (max-width: 600px) {
            font-size: 14px;
        }

        .search-category {
            color: $color-gray-2;
            font-family: $font-regular;
            margin-left: 8px;
            padding-left: 8px;
            position: relative;

            @media (max-width: 440px) {
                display: block;
                margin-left: 0;
                padding-left: 0;
                top: -4px;
            }

            &::before {
                background: $color-gray-6;
                content: '';
                height: 16px;
                left: 0;
                position: absolute;
                top: 3px;
                width: 1px;

                @media (max-width: 440px) {
                    display: none;
                }
            }

            .highlighted {
                color: $color-white;
            }
        }

        &:hover {
            background: $color-gray-6;
            border-radius: 3px;

            .highlighted {
                color: $color-yellow;
            }

            .search-category {
                color: $color-white;

                &::before {
                    background: $color-white;
                }
            }
        }
    }
}

.search-form {
    border-bottom: 1px solid $color-gray-6;
    display: flex;
    margin-top: 25px;
    max-width: 675px;
    position: relative;

    input[type='text'] {
        background: transparent;
        border: 0;
        border-radius: 0;
        color: $color-white;
        font-family: $font-regular;
        font-size: 16px;
        outline: 0;
        padding: 8px 0;
        width: 100%;

        @media (max-width: 600px) {
            font-size: 14px;
        }
    }

    .search-btn {
        background: transparent;
        border: 0;
        outline: 0;
    }
}

.search-results {
    color: $color-white;

    p {
        color: $color-white;
    }

    .pagenav {
        @include mixins.flex-row;

        background: transparent;
        border-top: 1px solid $color-gray-2;
        justify-content: center;
        margin: 32px 0 -17px;
        max-width: 675px;
        /* same as search-ilst */
        padding: 0 20px;
        text-align: center;

        a {
            color: $color-gray-5;
            font-size: 22px;
            height: 60px;
            line-height: 60px;
            min-width: 42px;
            padding: 0 5px;
            text-align: center;

            @media (max-width: 820px) {
                font-size: 18px;
                min-width: 30px;
            }

            span {
                display: inline-block;
                position: relative;
                transition: 0.4s;

                &::before,
                &::after {
                    background: $color-gray-2;
                    content: '';
                    height: 4px;
                    left: 0;
                    opacity: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    transition: 0.4s;
                }

                &::after {
                    bottom: 0;
                    top: auto;
                }
            }

            &:hover {
                span {
                    &::before,
                    &::after {
                        opacity: 1;
                    }
                }
            }

            &.active {
                color: $color-white;
                cursor: default;
                font-family: $font-semi-bold;

                span {
                    &::before,
                    &::after {
                        opacity: 1;
                    }
                }
            }
        }

        .next,
        .prev {
            background: url('../img/number-nav-arrow-grey.svg') center center no-repeat;
            background-size: 16px 16px;
            height: 42px;
            width: 60px;
        }

        .next {
            transform: rotate(-90deg);
        }

        .prev {
            transform: rotate(90deg);
        }
    }
}
