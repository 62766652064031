@use '../definitions' as *;

section.chat {
    background-color: $color-secondary;
    border-radius: 5px 0 0;
    bottom: 0;
    display: block;
    position: fixed;
    right: 0;
    width: 350px;
    z-index: 1000;

    @media (max-width: 415px) {
        /* no chat on small screens */
        display: none;
    }

    span a#mibew-agent-button {
        background: $color-secondary;
        background-image: url("../img/icon-bigger-green.svg");
        background-position: 310px 15px;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        border-radius: 5px 0 0;
        display: block;
        line-height: 1em;
        padding: 15px 20px;

        &:hover {
            background-image: url("../img/icon-bigger-grey.svg");
        }

        span {
            color: $color-white;
            padding: 0;
        }

        img {
            /* no mibew button displayed */
            display: none;
        }
    }
}

.mibew-chat-wrapper,
.mibew-chat-frame-toggle {
    @media (max-width: 415px) {
        display: none !important;
    }
}
