@use '../../definitions' as *;
/* header/navigation mainnav-site */

.mainnav-site {
    align-items: flex-end;
    display: flex;
    justify-content: flex-start;
    width: 100%;

    @media (max-width: 766px) {
        align-items: flex-start;
        flex-direction: column;
        margin: 1em auto 0;
        transform: translate(-0.5em, 0);
        width: calc(100% + 1em);
    }

    a {
        border-bottom: 5px solid transparent;
        line-height: 1;
        margin: 0 1em;
        padding-bottom: 0.9em;

        @media (max-width: 980px) {
            font-size: 0.85em;
            padding-bottom: 1em;
        }

        @media (max-width: 766px) {
            border: 0;
            font-size: 1em;
            line-height: 1;
            margin: 0;
            padding: 1em 0.5em;
            text-align: left;
            width: 100%;

            .search-icon {
                display: none;
            }
        }

        &:hover,
        &.active {
            border-color: $color-white;
        }

        &:hover,
        &:focus {
            @media (max-width: 766px) {
                background: $color-gray-4;
                color: $color-white;
            }
        }

        &.active {
            @media (max-width: 766px) {
                background: $color-gray-4;
            }

            &:hover {
                @media (max-width: 766px) {
                    color: $color-white;
                }
            }
        }

        &:first-child {
            margin-left: 0;
        }
    }

    .search-icon {
        border: 0;
        margin: 0;

        @media (max-width: 766px) {
            height: 2em;
            opacity: 0;
            position: absolute;
        }
    }

    .language-chooser {
        border-top: 1px solid $color-gray-6;
        margin: 1em 0.5em;
        width: 100%;

        @media (min-width: 766px) {
            display: none;
        }

        li {
            line-height: 3em;
            margin-top: 1em;
            text-align: left;
        }

        a {
            color: $color-gray-4;
            padding-left: 0;
        }
    }
}
