@use '../definitions' as *;

.points-list {
    margin: 86px auto 100px;

    .row {
        * {
            display: block;
            font-size: 15px;
            line-height: 1.2;
            margin: 0 auto;
            padding: 0;
            text-align: center;
        }

        h4 {
            color: $color-primary;
            font-family: $font-semi-bold;
        }

        p {
            color: $color-gray-2;
        }

        img {
            height: 60px;
            margin: 0 auto 20px;
            width: 60px;
        }
    }

    @media (max-width: 767px) {
        .row {
            * {
                margin: 0;
                text-align: left;
            }

            > div {
                display: flex;
                flex-direction: row;
                margin-bottom: 40px;
                width: 100%;

                div {
                    margin-left: 26px;
                }
            }

            img {
                height: 50px;
                width: 50px;
            }
        }
    }

    @media screen and (min-device-width: 768px) and (max-device-width: 991px) {
        max-width: 100%;

        > .row > div {
            padding: 0 23px;
        }
    }
}
