@use '../definitions' as *;

/* widgets download box */

.download-box {
    text-align: left;

    .select-detail {
        text-align: left;
    }

    .select-btn {
        font-family: $font-regular;
        font-size: 18px;
        margin-right: 20px;
        padding-bottom: 6px;
        padding-top: 6px;
    }
}
