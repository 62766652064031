@use '../definitions' as *;

/* header tophead */

.tophead {
    background: $color-gray;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    display: block;
    min-height: 100px;
    position: relative;
    z-index: 100;

    @media (max-width: 766px) {
        min-height: 40px;
    }

    .main {
        @media (max-width: 440px) {
            padding: 0 1.5em;
        }
    }
}
