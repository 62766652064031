@use '../definitions' as *;

/* matalog legend */
.legend {
    margin-bottom: 120px;
    text-align: center;

    span {
        color: $color-gray-2;
        display: inline-block;
        margin: 0 16px;
        padding-left: 36px;
        position: relative;

        &::before {
            background-size: contain;
            content: "";
            height: 13px;
            left: 0;
            margin-top: -6.5px;
            position: absolute;
            top: 50%;
            width: 18px;
        }
    }

    .access-full {
        color: $color-secondary;

        &::before {
            background: url('../img/legend-access-full.svg') center center no-repeat;
        }
    }

    .access-limit {
        color: $color-gray-2;

        &::before {
            background: url('../img/legend-access-limit.svg') center center no-repeat;
        }
    }
}
