@use '../definitions' as *;
@use '../mixins';

.paired-mojeid-nia {
    &.container {
        @media (min-width: 1200px) {
            max-width: 51.875em;
        }
    }

    h1 {
        font-family: $font-bold;
        font-size: 2em;
        line-height: 1.3125;
    }

    h2 {
        font-size: 1.5em;
    }

    p {
        color: $color-gray-6;
    }

    strong {
        color: $color-black;
    }

    .image-box {
        align-items: center;
        display: flex;
        height: 110px;
        justify-content: center;
    }
}

.list-number > * {
    align-items: center;
    border-radius: 50%;
    display: flex;
    font-family: $font-bold;
    height: 32px;
    justify-content: center;
    width: 32px;
}

.arrow-list {
    ul {
        list-style-type: none;
        padding-left: 0;
    }

    li {
        display: inline-block;
        padding-right: 1em;
        position: relative;

        &::before {
            border-left: 11px solid transparent;
            border-top: 11px solid $list-number;
            bottom: 0;
            content: '';
            height: 0;
            left: -20px;
            margin: auto;
            position: absolute;
            top: 0;
            transform: rotate(45deg);
            width: 0;
        }

        &:first-of-type::before {
            border-top: 11px solid transparent;
        }
    }
}

.text-small p {
    font-size: 0.8em;
}

.image-center {
    display: block;
    margin: auto;
    max-width: 80%;
}
