@use '../definitions' as *;

/* layout links inside p, ul */

p,
ul li {
    a {
        color: $color-primary;
        text-decoration: underline;

        &:hover {
            color: $color-primary;
        }
    }
}
