@use 'definitions' as *;
@use 'mixins';

.reverse-order {
    @media (max-width: 991px) {
        flex-wrap: wrap-reverse;
    }
}

.main {
    @include mixins.page-width();
    @include mixins.center();

    position: relative;

    @media (max-width: 440px) {
        padding: 0 9px;
    }
}

.no-padding {
    padding: 0;
}

.only-phone {
    display: none;
}

@media (max-width: 730px) {
    .only-phone {
        display: block;
    }
}

.col-50 {
    @include mixins.flex-col(50%);

    @media (max-width: 730px) {
        @include mixins.flex-col(100%);
    }
}
