@use '../definitions' as *;

// /* FAQ dropdown */
.collapse-box {
    background: $color-gray-3;
    margin-bottom: 130px;
    padding: 26px 105px 60px;

    @media (max-width: 767px) {
        margin-bottom: 65px;
        padding: 26px 15px 60px;
    }

    h2 {
        color: $color-primary;
        font-family: $font-bold;
        font-size: 32px;
        margin-bottom: 60px;
        position: relative;
        text-align: center;

        @media (max-width: 767px) {
            font-size: 23px;
            margin-bottom: 20px;
            padding-left: 15px;
        }

        &::after {
            background: $color-gray-5;
            bottom: -20px;
            content: '';
            height: 1px;
            left: -10%;
            position: absolute;
            right: 0;
            width: 120%;

            @media (max-width: 767px) {
                left: 0;
                width: auto;
            }
        }
    }

    .back {
        background: url('../img/icon-back.svg') center center no-repeat;
        background-size: contain;
        height: 36px;
        left: -58px;
        position: absolute;
        top: 0;
        width: 36px;
        z-index: 2;

        @media (max-width: 767px) {
            height: 23px;
            left: 15px;
            width: 23px;
        }
    }

    .collapse {
        position: relative;

        &::after {
            background: $color-gray-5;
            bottom: 0;
            content: '';
            height: 1px;
            left: 0;
            position: absolute;
            right: 0;
        }
    }

    .collapse,
    .collapsing {
        padding-right: 40px;

        @media (max-width: 767px) {
            padding-right: 0;
        }
    }

    [aria-expanded='true'] {
        cursor: pointer;
        padding: 18px 1px;
        transition: all 0.2 ease-in;

        a {
            color: $color-gray;
            display: block;
            font-family: $font-semi-bold;
            text-decoration: none;
        }

        > * {
            color: $color-gray;
            font-family: $font-semi-bold;
            margin: 0;
            padding-right: 30px;
            position: relative;

            &::after {
                background: url('../img/icon-close-gray.svg') center center
                    no-repeat;
                content: '';
                display: block;
                height: 25px;
                opacity: 1;
                position: absolute;
                right: 0;
                top: 4px;
                transform: rotate(0);
                transition: all 0.2 ease-in;
                width: 25px;
            }
        }
    }

    [aria-expanded='false'] {
        border-bottom: 1px solid $color-gray-5;
        cursor: pointer;
        padding: 18px 1px;
        transition: all 0.2 ease-in;

        a {
            color: $color-gray-2;
            display: block;
            text-decoration: none;
        }

        > * {
            color: $color-gray-2;
            font-family: $font-regular;
            font-size: 18px;
            margin: 0;
            padding-right: 30px;
            position: relative;

            &::after {
                background: url('../img/icon-close-gray.svg') center center
                    no-repeat;
                content: '';
                display: block;
                height: 25px;
                opacity: 0.4;
                position: absolute;
                right: 0;
                top: 4px;
                transform: rotate(45deg);
                width: 25px;
            }

            &:hover {
                &::after {
                    opacity: 1;
                    transition: all 0.2 ease-in;
                }
            }
        }
    }

    > div:nth-last-of-type(2),
    > div:last-of-type {
        border-bottom: 0;
    }

    div[role='tabpanel'] {
        &.collapse {
            transition: padding-bottom 0.2s ease-out;
        }

        &.collaping,
        &.collapse.show {
            padding-bottom: 40px;
        }
    }
}
