/* button with svg */

.btn-with-svg {
    .svg-box {
        position: relative;
    }

    img {
        position: absolute;
        transition: all 0.15s ease-in-out;

        &:first-of-type {
            opacity: 1;
        }

        &:last-of-type {
            opacity: 0;
        }
    }

    &:hover {
        img {
            &:first-of-type {
                opacity: 0;
            }

            &:last-of-type {
                opacity: 1;
            }
        }
    }
}
