@use '../definitions' as *;
@use '../mixins';

/* widgets elect dropdown */

.select-btn {
    background: $color-gray-15;
    color: $color-gray;
    display: inline-block;
    font-family: $font-semi-bold;
    font-size: 22px;
    margin-top: -26px;
    position: relative;
    transform: translate(2px, 0);
    width: 100%;
    z-index: 2;

    @media (min-width: 767px) {
        border-radius: 6px 0 0 6px;
    }

    @media (max-width: 890px) {
        transform: translate(1px, 0);
    }

    select {
        appearance: none;
        background: transparent;
        border: 0;
        color: $color-black;
        cursor: pointer;
        display: inline-block;
        font-size: 22px;
        line-height: 40px;
        outline: none;
        padding: 10px 60px 10px 20px;
        position: relative;
        width: 100%;

        option {
            font-size: 16px;
            height: 25px;
        }

        @media (max-width: 440px) {
            font-size: 16px;
            line-height: 29px;
        }
    }

    &::before {
        background: url('../img/select-down.svg') center center no-repeat;
        background-size: 16px 16px;
        bottom: 0;
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 45px;
    }
}

.select-detail {
    width: 100%;
}

.select-list {
    margin-top: 60px;
}

.select-item {
    @include mixins.flex-row();

    p {
        a {
            font-family: $font-semi-bold;
        }
    }

    .box {
        background: $color-gray-3;
        padding: 15px 20px 20px;

        @media (max-width: 600px) {
            margin-bottom: 20px;
        }
    }

    .day {
        display: inline-block;
        min-width: 36px;
    }

    li.gray {
        color: $color-gray-2;
    }
}
