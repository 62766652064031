@use '../definitions' as *;

/* matalog catalog */
.catalog {
    .search-form {
        border-bottom: 0;
        margin-top: 0;
        max-width: none;

        input[type='text'] {
            background: $color-gray-3;
            border-radius: 4px;
            color: $color-black;
            font-size: 22px;
            padding: 10px 20px;

            @media (max-width: 440px) {
                font-size: 16px;
            }
        }

        .search-btn {
            background-color: $color-yellow;
            background-image: url('../img/search-zoom-black.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 24px 24px;
            height: 60px;
            width: 65px;

            &:hover,
            &:focus {
                background-image: url('../img/search-zoom-white.svg');
            }

            @media (max-width: 440px) {
                background-size: 24px 24px;
                height: 52px;
                width: 52px;
            }

            @media (min-width: 767px) {
                border-radius: 0 6px 6px 0;
            }
        }

        ::placeholder {
            color: $color-gray-6;
        }
    }

    .sub-title {
        margin-top: 0;
        white-space: nowrap;

        @media (max-width: 890px) {
            white-space: pre-wrap;
        }
    }

    .select-btn {
        font-family: $font-regular;
        margin-top: 0;
    }

    .select-list {
        margin-top: 0;
    }

    .select-item {
        align-items: inherit;
        border-top: 1px solid $color-gray-5;

        &:first-of-type {
            border-top: 0;
            padding-top: 3em;
        }

        .image {
            margin-bottom: 25px;
            text-align: center;

            @media (max-width: 767px) {
                text-align: left;
            }

            img {
                height: auto;
                max-width: 100%;
            }
        }
    }

    .tags {
        span {
            color: $color-gray-2;
            display: inline-block;
            height: 13px;
            padding-right: 18px;
            position: relative;

            &:first-child {
                padding-right: 36px;
            }

            &::before {
                background-size: contain;
                content: '';
                height: 13px;
                left: 0;
                margin-top: -6.5px;
                position: absolute;
                top: 50%;
                width: 18px;
            }

            &.access-full {
                &::before {
                    background: url('../img/legend-access-full.svg') center center no-repeat;
                }
            }

            &.access-limit {
                &::before {
                    background: url('../img/legend-access-limit.svg') center center no-repeat;
                }
            }
        }
    }
}
