@use '../definitions' as *;
@use '../mixins';

.blocks-small {
    @include mixins.bottom-box();

    background: $color-gray-3;
    margin-bottom: 96px;
    padding-top: 44px;
    position: relative;

    h2 {
        font-family: $font-bold;
        font-size: 32px;
        margin-bottom: 50px;
        margin-top: 0;
        text-align: center;
    }
}
