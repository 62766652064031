// line-height
.line-height-1 * {
    line-height: 1;
}

.line-height-1-05 * {
    line-height: 1.05;
}

.line-height-1-1 * {
    line-height: 1.1;
}

.line-height-1-15 * {
    line-height: 1.15;
}

.line-height-1-2 * {
    line-height: 1.2;
}
