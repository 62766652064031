@use '../definitions' as *;

/* widgets price */

.price {
    margin-left: auto;
    margin-right: auto;
    max-width: 760px;

    p {
        margin-left: 20px;
        margin-right: 20px;

        @media (max-width: 600px) {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.price-table {
    td,
    th {
        color: $color-black;
        text-align: center;

        &:first-child {
            text-align: left;

            @media (max-width: 600px) {
                text-align: center;
            }
        }
    }
}

.explanation {
    border-top: 1px solid $color-gray-5;
    margin-top: 50px;
    padding-top: 25px;

    ol,
    ul {
        li {
            color: $color-gray-2;
            font-size: 14px;
        }
    }

    p {
        color: $color-gray-2;
        font-size: 14px;
        padding-left: 18px;
        position: relative;
    }

    .sup {
        left: 0;
        position: absolute;
        top: 0;
    }
}
