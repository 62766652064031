/* fonts */
@font-face {
    font-family: 'PublicSans-Regular';
    src: url('../fonts/PublicSans-Regular.ttf');
}

@font-face {
    font-family: 'PublicSans-Medium';
    src: url('../fonts/PublicSans-Medium.ttf');
}

@font-face {
    font-family: 'PublicSans-SemiBold';
    src: url('../fonts/PublicSans-SemiBold.ttf');
}

@font-face {
    font-family: 'PublicSans-Bold';
    src: url('../fonts/PublicSans-Bold.ttf');
}

/* fonts */

$font-regular: 'PublicSans-Regular';
$font-medium: 'PublicSans-Medium';
$font-semi-bold: 'PublicSans-SemiBold';
$font-bold: 'PublicSans-Bold';

/* colors */

$color-primary: #1492ff; // buttons, headlines
$color-primary-hover: #0f76cf;
$primary-gradient: radial-gradient(circle, #1492ff 0%, #0f76cf 100%);
$color-secondary: #6dc35f; // buttons, table highlights
$color-black: #000;
$color-white: #fff;
$color-gray: #404040; // tophead, footer
$color-gray-2: #808080;
$color-gray-3: #f7f7f7;
$color-gray-4: #ababab;
$color-gray-5: #e6e6e6; // border faq
$color-gray-6: #555; // paired mojeid with nia
$color-gray-7: #9e9c9c;
$color-gray-8: #f6f6f6;
$color-gray-9: #dadada;
$color-gray-10: #3c3c3c;
$color-gray-11: #afafaf;
$color-gray-12: #f2f2f2;
$color-gray-13: #bfbfbf;
$color-gray-14: #838995;
$color-gray-15: #eaeaea;
$color-yellow: #ffc200;
$list-number: #e6b800;
$empty: #edf4fa;
$btn-color: #473700;

/* messagelist */

$debug: #ccc;
$info: #cab999;
$warning: #f6d128;
$success: #32c518;
$error: #bd122e;
