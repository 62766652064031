@use '../definitions' as *;
@use '../mixins';

/* widgets stats */

.stats {
    @include mixins.bottom-box();

    margin-bottom: 120px;
    text-align: center;

    .row {
        align-items: inherit;
    }
}

.stats-item,
.stats-item-large {
    padding: 20px 0;

    @media (max-width: 440px) {
        padding: 8px 0;
    }

    .number {
        color: $color-primary;
        display: block;
        font-family: $font-semi-bold;
        font-size: 26px;
        line-height: 1.5;
    }

    p {
        color: $color-gray-2;
    }
}

.stats-item-large {
    .number {
        font-size: 96px;
        line-height: 1;

        @media (max-width: 730px) {
            font-size: 70px;
        }

        @media (max-width: 440px) {
            font-size: 48px;
        }
    }

    p {
        font-size: 36px;

        @media (max-width: 730px) {
            font-size: 26px;
        }
    }
}

.stats-item {
    .number {
        @media (max-width: 600px) {
            font-size: 32px;
        }

        @media (max-width: 440px) {
            font-size: 18px;
        }
    }

    p {
        @media (max-width: 600px) {
            font-size: 18px;
        }
    }
}
