@use "sass:math";
/* mixins */

/*
font-size: calc([minimum size] + ([maximum size] - [minimum size])
* ((100vw - [minimum viewport width])
/ ([maximum viewport width] - [minimum viewport width])));
*/
@function stripunit($value) {
    @return math.div($value, ($value * 0 + 1));
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size ) {
    $min: unit($min-vw);
    $max: unit($max-vw);
    $minf: unit($min-font-size);
    $maxf: unit($max-font-size);

    @if $min == $max and $min == $minf and $min == $maxf {
        body & {
            font-size: $min-font-size;

            @media screen and (min-width: $min-vw) {
                font-size:
                    calc(#{$min-font-size} + #{stripunit($max-font-size - $min-font-size)} *
                    ((100vw - #{$min-vw}) / #{stripunit($max-vw - $min-vw)}));
            }
            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size;
            }
        }
    }
}

@mixin page-width() {
    max-width: 1000px;
    padding: 0 30px;
    width: 100%;
}

@mixin center {
    margin-left: auto;
    margin-right: auto;
}

@mixin flex($values) {
    flex: $values;
}

@mixin flex-row {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    margin-left: -15px;
    margin-right: -15px;
}

@mixin flex-col($width) {
    @include flex(1 $width);

    max-width: $width;
    padding: 0 15px;
}

@mixin bottom-box {
    @media (max-width: 980px) {
        margin-bottom: 100px;
    }

    @media (max-width: 730px) {
        margin-bottom: 50px;
    }
}
