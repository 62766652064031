@use '../definitions' as *;

/*
    Mojeid validation places
*/

.mojeid-validation-places-map {
    .card-header {
        h3 {
            margin-bottom: 0.4em;

            * {
                color: $color-primary;
            }
        }
    }

    .mojeid-marker-content {
        h3 {
            margin-bottom: 0.2em;
        }

        .opening-hours {
            margin-top: 1em;
        }

        a {
            color: $color-primary;
            font-size: 100%;

            &:hover {
                text-decoration: underline;
            }
        }

        table.times {
            margin-bottom: 0.4em;
            margin-left: 1em;
            width: auto;

            tr td {
                background-color: $color-white;
                display: table-cell;
                font-size: 90%;
                padding: 0 1.2em 0 0;

                &:first-child {
                    font-family: $font-semi-bold;
                }

                &:not(:first-child) {
                    text-align: right;
                }

                @media (max-width: 600px) {
                    &:first-child {
                        text-align: left;
                    }
                }
            }

            tr.closed {
                td {
                    color: $color-gray-4;
                }
            }
        }
    }
}

.mojeid-validation-places,
.mojeid-validation-places-seznam {
    .mojeid-hidden-city {
        display: none;
    }

    .mojeid-place-info {
        font-size: 16px;
        margin-bottom: 25px;
    }

    .select-list {
        .select-item {
            align-items: flex-start;
        }

        .box {
            margin: 10px 0;

            h3 {
                margin: 0;

                a {
                    color: $color-primary;
                    font-family: $font-bold;
                    font-size: 22px;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            a.show-on-map {
                color: $color-primary;
                font-size: 90%;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
