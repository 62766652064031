@use '../definitions' as *;

.width-xxl {
    .container {
        max-width: 103em;
    }
}

.width-xl {
    .container {
        max-width: 87.5em;
    }
}

.blue-bg {
    background-color: $color-primary;
}

.primary-gradient {
    background: $primary-gradient;
}

.blue-grey-gradient {
    background: $color-primary;
    background: linear-gradient(90deg, $color-primary 50%, $color-gray-8 50%);
}

.blue-white-gradient {
    background: $color-primary;
    background: linear-gradient(90deg, $color-primary 50%, $color-white 50%);
}

.grey-bg {
    background-color: $color-gray-8;
}

.grey2-bg {
    background-color: $color-gray-10;
}

.text-white * {
    color: $color-white;
}

.text-grey * {
    color: $color-gray-11;
}

.mw-100-img img {
    max-width: 100%;
}

.mw-md-60-img img {
    @media (max-width: 48em) {
        max-width: 60%;
    }
}

.translateb-4em-img img {
    transform: translate(0, 4em);

    @media (max-width: 48em) {
        transform: translate(0, 2em);
    }
}

.bg-img {
    background-color: $color-black;
    display: inline-block;
    padding: 1em 1.25em 0.7em;
}

.niakampf-hp {
    .btn {
        border-radius: 3px;
        display: inline-block;
        font-family: $font-bold;
        font-size: 1.4em;
        margin-top: 1.6em;
        padding: 0.5em 1.5em;
        text-align: center;
        text-decoration: none;
        text-transform: none;
        transition: all 0.157s ease-in-out;

        @media (max-width: 48em) {
            font-size: 1.2em;
        }
    }

    .btn-primary {
        background-color: $color-primary;
        border: 1px solid $color-primary;
        color: $color-white;

        &:hover,
        &:focus,
        &:active:focus {
            background-color: $color-primary-hover;
            border: 1px solid $color-primary-hover;
            box-shadow: none;
        }
    }

    h1 {
        font-size: 4.25em;

        @media (max-width: 48em) {
            font-size: 3.125em;
        }
    }

    h2 {
        font-size: 1.8em;

        @media (max-width: 48em) {
            font-size: 1.6em;
        }
    }

    p {
        font-size: 1.25em;
        line-height: 1.5;

        @media (max-width: 48em) {
            font-size: 1em;
        }
    }

    .text-small * {
        font-size: 1.1em;

        @media (max-width: 48em) {
            font-size: 1em;
        }
    }
}
